// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.story-list {
  padding: 0;
}

.backlog-list {
  .card-filtered {
    display: none;
  }
}

.target-group {
  @extend .pt-3;
}

.dropzone {
  @extend .card;

  align-content: center;
  background: transparent;
  border-style: dashed;
  border-width: 4px;
  display: flex;
  height: 4.75rem;
  justify-content: center;
  text-align: center;
  z-index: 1;
}

.dropzone-wrapper {
  position: relative;
}

.dropzone-label {
  margin-left: -1.25rem;
  position: absolute;
  text-align: center;
  top: 4.1rem;
  width: 100%;
  z-index: 0;
}

.sortable-ghost {
  background: rgba($primary, .2);
  opacity: 0.5;

  .dropzone & {
    align-items: stretch;
  }
}

#alert-container {
  bottom: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 99999; // Always be on top!

  @include media-breakpoint-up(md) {
    max-width: 450px;
  }

  .alert {
    margin: 0.5rem;
  }
}

.story-header {
  display: flex;
  justify-content: space-between;

  span {
    flex-shrink: 0;
    align-self: start;
  }
}

.story-date-picker {
  width: 10rem;
}

.done-flag {
  @extend .badge, .badge-success;
  font-size: 1rem;
  align-items: center;
  display: flex;
  position: absolute;
  left: -10px;
  top: -2px;
  transform: rotate(-12deg);
  -webkit-animation-name: pulse;
  animation-name: pulse;
  animation-delay:0.5s;
  -webkit-animation-delay:0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  opacity: 0;
  -webkit-animation-fill-mode:forwards;
   animation-fill-mode:forwards;

  svg {
    margin-right: 0.375rem;
  }
}

@keyframes pulse {
  0%{
    opacity: 0;
  }

  10%{
    opacity:.50;
    transform-origin: 50% 50%;
    transform: rotate(-2deg) scale(5);
    transition: all .3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }

  100%{
    opacity:1;
    transform: rotate(-15deg) scale(1);
  }
}

.relationship-meta {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  min-width: 0;
}

.hierarchy-meta {
  display: flex;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.feature-label,
.ancestor-label {
  white-space: nowrap;
  display: inline-flex;
  min-width: 0;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;

  .badge {
    margin-top: 0.125rem;
  }
}

.feature-label {
  flex-shrink: 0;
}

.ancestor-label {
  flex-shrink: 1;
}

.ancestor-label a,
.truncatable {
  overflow: hidden;
  text-overflow: ellipsis;
}

li.changed {
  border-color: $green;
  position: relative;

  .changed-by .user-card {
    position: absolute;
    top: -15px;
    right: -20px;
    img {
      height: 35px;
      width: 35px;
    }
    .name {
      display: none;
    }
  }
}

li.done {
  color: $gray-500;

  a {
    color: $gray-400;
  }

  .badge-primary {
    background-color: $gray-500;
  }
}

.story-tasks {
  @extend .list-group;

  padding: 0;
  list-style: none;

  .form-check-input {
    cursor: pointer;
  }

  .user-card {
    position: absolute;
    left: -35px;

    img {
      width: 32px;
      height: 32px;
    }
  }
}

.story-task {
  @extend .list-group-item;

  padding: 0.375rem 0 0.375rem 0.75rem;
}

.task-completed {
  .form-control {
    color: $gray-400;
    text-decoration: line-through;
  }
  &.sortable-ghost .form-control,
  &.sortable-drag .form-control {
    color: $gray-700;
    text-decoration: none;
  }
}

.story-task .task-body {
  background: transparent;
  border: 1px solid transparent;
  height: auto;
  text-align: left;
  padding: 0 0 0 .5rem;
}

.delete-task, .delete-split {
  @extend .btn, .btn-sm, .btn-outline-danger;
  border: none;
}
.delete-split {
  align-self: center;
}

.issueBody {
  position: relative;

  li p {
    margin-bottom: 0;
  }

  .ProseMirror {
    padding: 1rem;
  }

  .ProseMirror-menubar {
    background-color: $gray-100;
    padding: .5rem;
    margin-bottom: .5rem;
  }

  .btnRow {
    padding: .5rem 0;
  }


  .richEditorWrapper {
    padding: 0;

    .btnRow {
      padding: 0.5rem 1rem 1.5rem;
    }
  }

  .btn-outline-primary:not(:hover),
  .btn-outline-danger:not(:hover) {
    background: $white;
  }

  .markdownEditor {
    width: 100%;
    min-height: 300px;
  }
}

.issueView img,
.comment-body img {
  max-width: 100%;
}

h1.issueTitle {
  line-height: 1.4;
}

.story-title-wrapper {
  border: 2px solid transparent;
}

.story-points {
  color: $white;
  line-height: 1.5;
  background-color: $yellow;

  &.points-unpointed {
    background-color: $points-unpointed;
    color: $black;
  }

  &.points-unsure {
    color: $points-unsure;
  }

  &.points-0 {
    background-color: $points-0;
  }

  &.points-1 {
    background-color: $points-1;
  }

  &.points-2 {
    background-color: $points-2;
  }

  &.points-3,
  &.points-4 {
    background-color: $points-3;
  }

  &.points-5,
  &.points-6,
  &.points-7 {
    background-color: $points-5;
  }

  &.points-8,
  &.points-9,
  &.points-10,
  &.points-11,
  &.points-12 {
    background-color: $points-8;
  }

  &.points-13 {
    background-color: $points-13;
  }

  &.points-21 {
    background-color: $points-21;
  }

  &.points-34 {
    background-color: $points-34;
  }

  &.points-55 {
    background-color: $points-55;
  }

  &.points-89 {
    background-color: $points-89;
  }

  &.points-144 {
    background-color: $points-144;
  }

  &.points-233 {
    background-color: $points-233;
  }

  &.user-vote {
    outline: solid 2px $black;
  }
}

button.story-points,
h4.story-points {
  display: block;
  float: left;
  width: 42px;
  height: 42px;
  margin: 0 10px 10px 0;
  border: solid 1px $white;
}

button.story-points:hover {
  border: solid 1px $gray-800;
}

h4.story-points {
  text-align: center;
  height: 46px;
  width: 46px;
  line-height: 46px;
}

pre {
  background-color: $gray-100;
  padding: 0.5em;
  border: solid 1px $gray-300;
}

.team-bar {
  .user-strip {
    display: flex;
    flex-wrap: wrap;
  }
}

.user-card {
  float: left;
  background-color: $gray-100;
  margin: 0 10px 10px 0;
  position: relative;
  border-radius: 3px;
  img {
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
  .name {
    display: block;
    position: absolute;
    top: -6px;
    left: -6px;
    background-color: $gray-600;
    color: $white;
    padding: 0 4px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 0.8em;
  }
}

.user-card.status-done {
  img {
    border: solid 1px $green;
  }
  .name {
    background-color: $green;
  }
}

.user-card.status-info {
  img {
    width: 46px;
    height: 46px;
  }
  .name {
    background-color: $gray-200;
    color: $black;
  }
}

.user-card.status-waiting {
  img {
    border: solid 1px $red;
  }
  .name {
    background-color: $red;
  }
}

.user-card.status-offline {
  img {
    border: solid 1px $gray-400;
  }
  .name {
    background-color: $gray-400;
  }
  img {
    filter: opacity(40%);
  }
}

.user-card.status-online {
  img {
    border: solid 2px $green;
  }
  .name {
    background-color: $green;
  }
}

.user-strip {
  text-align: right;
}

.user-strip .user-card {
  float: none;
  display: inline-block;
  margin-right: 0;
  margin-left: 6px;
  img {
    height: 27px;
    width: 27px;
  }
  .name {
    display: none;
  }
  &.status-offline img {
    filter: opacity(40%) blur(1px);
  }
  &:hover {
    cursor: pointer;
    .name {
      display: block;
      height: 27px;
      width: 27px;
      top: 0;
      left: 0;
      border-radius: 50%;
      text-align: center;
      line-height: 27px;
    }
  }
}

.story-id {
  @extend .badge, .badge-primary;
}

.card-top,
.card-bottom {
  display: flex;
  justify-content: space-between;
}

.card-top {
  align-items: center;

  .story-done-check,
  .story-date,
  .story-id,
  .story-flag-poker
  {
    margin-right: 0.25rem;
  }
}
.card-bottom {
  align-items: flex-end;
}

.story-flag-poker {
  @extend .badge, .badge-danger;

  &.voted {
    @extend .badge-success;
  }

}
.story-done-check {
  font-size: 1.2rem;
}

.story-date {
  @extend .badge, .badge-light;
  font-size: 1rem;
  background-color: $gray-200;
  svg {
    vertical-align: bottom;
  }
}

.story-title {
  flex-grow: 1;
}

.comments {
  ol.comments-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-radius: 0.25rem;
  }

  .comment {
    @extend .list-group-item;
    display: flex;

    .user-card {
      background-color: transparent;
      flex-grow: 0;
      flex-shrink: 0;
      float: none;
    }

    .comment-meta {
      margin-bottom: 0.5rem;
    }

    .commenter {
      @extend .font-weight-bolder;
    }

    .comment-time {
      @extend .text-muted;
    }
  }
}

.comment.highlighted {
  border-width: 1px !important;
  border-color: $primary;

  .comment + & {
    margin-top: -1px;
  }
}

.comment p:last-child {
  margin-bottom: 0;
}

.comment-actions {
  button {
    @extend .btn, .btn-sm, .btn-link;
    margin-left: 0.25rem;
    padding: 0;
  }

  .delete-comment, .delete-comment:hover {
    color: $danger;
  }
}

.team-members-list {
  list-style: none;
  padding: 0;

  img {
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  button {
    visibility: hidden;
  }

  li {
    align-items: center;
    border-radius: 0.25rem;
    display: flex;
    justify-content: flex-start;
    padding: 0.25rem;
  }

  li:hover {
    background: #f8f8f8;

    button {
      visibility: visible;
    }
  }

  .team-members-list-name {
    flex-grow: 1;
  }
}

.contributors-list {
  display: flex;
  list-style: none;
  padding: 0;

  .user-card {
    background: none;
    float: none;
    margin: 0 0.25rem 0 0;
  }
}

.story-contributors {
  @extend .text-center;

  .contributors-list {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .user-card {
    margin: 0.5rem;
  }
}

.board-columns {
  @extend .card-group;
}

.board-column {
  @extend .card;

  h2 {
    @extend .h4, .card-header, .text-center;
  }

  .card-body {
    display: flex;
    flex-flow: column nowrap;

    .story-list {
      flex: 1 0 auto;
    }
  }

  .column-done {
    position: relative;

    &::before {
      background-color: rgba(255,255,255,0.8);
      border-color: $secondary;
      border-style: dashed;
      border-width: 4px;
      bottom: 0;
      color: $secondary;
      content: 'Mark as done';
      display: none;
      flex-flow: row nowrap;
      font-size: 2rem;
      font-weight: 700;
      justify-content: center;
      left: 0;
      line-height: 1;
      padding: 0.5rem;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100;
    }

    &.highlight {
      &::before {
        display: flex;
      }
    }

    .sortable-ghost {
      display: none;
    }
  }
}

.action-menu {
  display: flex;
  justify-content: flex-end;
}

.dropdown-item .btn {
  padding: 0;

  &:disabled, &.disabled {
    background: #fff;
    border-color: #fff;
    color: $gray-500;
    cursor: not-allowed;
  }
}

.dropdown-item .danger {
  color: $danger;
  text-decoration: none;
}

.active-badge {
  @extend .badge, .badge-primary;

  position: absolute;
  left: -1px;
  top: -1px;
}

.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;

  .spinner-border {
    width: 5rem;
    height: 5rem;
    border-width: 0.5rem;
  }
}

.backlog-filters {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.5rem;

  a.btn {
    padding: 0.375rem;
  }

  .contributors-list {
    margin-bottom: 0;
  }
}

// Increase specificity to override library default.
.react-dropdown-select-input.react-dropdown-select-input {
  border: 0;
  font-size: 1em;

  span:not(:empty) + & {
    width: 0;
  }
}

.react-dropdown-select {
  background-color: #fff;
}

.ReactModal__Overlay {
  z-index: 1001; // 1 more than drop downs
}

.confirmation-dialog {
  position: absolute;
  width: 90%;
  height: 90%;
  max-width: 640px;
  max-height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.burndown-detail {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  max-width: 80vw;
  outline: none;
}

.display-inline {
  display: inline;
}

.sticky-sidebar {
  @extend .sticky-top;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 999; // 1 less than dropdowns

  h4.mt-3:first-of-type {
    @extend .h2;

    .context-team & {
      display: none;
    }
  }
}

.list-group-item-numbered {
  display: list-item;
  list-style-position: inside;
}

.contexts-bucket {
  @extend .card, .mt-3;

  >h2 {
    @extend .card-header;
  }

  .backlog-bucket {
    @extend .card-body;
    &:not(:first-of-type) {
      padding-top: 0;
    }
  }

  .backlog-list {
    margin-bottom: 0;
  }

  &:last-child {
    @extend .mb-4;
  }
}

.instigator-wrapper {
  border: 1px solid transparent;
}

@include media-breakpoint-up(md) {
  .sidebar-bg {
    background-image: linear-gradient(to right, #ffffff 60%, #f1f2f3 60%);

    main.container {
      background: #fff;
    }
  }
}

.team-bar {
  h2 {
    margin-bottom: 0;
  }

  .board-button {
    @extend .btn, .btn-primary;
  }
}

.team-bar-wrapper {
  background-color: #f1f2f3;
  border-bottom: $card-border-width solid $card-border-color;

  .team-bar {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0;
  }

  h2 {
    font-size: 1.35rem;
  }

  .active-team-header {
    padding-left: 0.75rem;
  }

  .board-button {
    @extend .btn-sm;

    margin-left: 0.75rem;
    order: 2;
  }

  .user-strip {
    margin-left: 0.5rem;
    margin-top: 0.25rem;
    order: 3;
  }

  .user-card {
    margin-bottom: 0;
  }
}

.team-sidebar {
  @extend .col-md-3, .py-3;
  background-color: #f1f2f3;
  border-left: $card-border-width solid $card-border-color;

  .team-bar {
    display: block;
    padding: 0;
  }

  .user-strip {
    padding: 0.5rem 0;
  }

  .user-card {
    margin: 0 2px 2px 0;
    background: none;

    img {
      background-color: #fff;
    }
  }
}

.new-sprint-data-wrapper {
  @include make-col-ready();
  margin-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    @include make-col(3);
    order: 2;
  }

  @include media-breakpoint-up(xl) {
    @include make-col(5);
  }

  ol {
    @include media-breakpoint-up(lg) {
      width: 200px;
    }
  }
}

.new-sprint-form-wrapper {
  @include make-col-ready();

  @include media-breakpoint-up(lg) {
    @include make-col(9);
    order: 1;
  }

  @include media-breakpoint-up(xl) {
    @include make-col(7);
  }

  .dash {
    display: none;

    @include media-breakpoint-up(md) {
      @include make-col(1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.backlog-screen .breadcrumb {
  padding-top: 0;
}

.source-label {
  white-space: normal;
  display: inline-block;
}

.timeline {
  .timeline-item {
    position: relative;
  }

  .timeline-avatar {
    position: relative;
    box-shadow: #fff 0px 0px 0px 0px, #fff 0px 0px 0px 6px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }

  h3 {
    margin: 0;
    font-size: 1.3rem;
  }

  .timeline-meta {
    margin: 0;
  }

  .timeline-connector {
    position: absolute;
    top: 17px;
    left: 15px;
    background: #999;
    height: 100%;
    width: 2px;
  }

  .timeline-changes {
    strong {
      background: #def;
      padding: 3px;
    }
  }
}
