@use 'sass:list';
@use 'sass:map';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Body
$body-bg: #f8fafc;

// Typography
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Point Scale
$point-scale: (
  "unpointed" : $gray-100,
  "unsure" : $gray-700,
  "0": $blue,
  "1": $indigo,
  "2": $purple,
  "3": $pink,
  "4": $pink,
  "5": $red,
  "6": $red,
  "7": $red,
  "8": $orange,
  "9": $orange,
  "10": $orange,
  "11": $orange,
  "12": $orange,
  "13" : $green,
  "14": $green,
  "15": $green,
  "16": $green,
  "17": $green,
  "18": $green,
  "19": $green,
  "20": $green,
  "21" : $teal,
  "22": $teal,
  "23": $teal,
  "24": $teal,
  "25": $teal,
  "26": $teal,
  "27": $teal,
  "28": $teal,
  "29": $teal,
  "30": $teal,
  "31": $teal,
  "32": $teal,
  "33": $teal,
  "34" : $cyan,
  "55" : $gray-800,
  "89" : $gray-700,
  "144" : $gray-600,
  "233" : $gray-500,
);

$points-unpointed: map.get($point-scale, "unpointed");
$points-unsure: map.get($point-scale, "unsure");
$points-0: map.get($point-scale, "0");
$points-1: map.get($point-scale, "1");
$points-2: map.get($point-scale, "2");
$points-3: map.get($point-scale, "3");
$points-5: map.get($point-scale, "5");
$points-8: map.get($point-scale, "8");
$points-13: map.get($point-scale, "13");
$points-21: map.get($point-scale, "21");
$points-34: map.get($point-scale, "34");
$points-55: map.get($point-scale, "55");
$points-89: map.get($point-scale, "89");
$points-144: map.get($point-scale, "144");
$points-233: map.get($point-scale, "233");
